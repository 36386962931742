type NavItem = {
  title?: string
  key: string
  items: {
    link?: string
    name: string
    type: 'internal' | 'external' | 'notifications' | 'switcher'
  }[]
}

type footerItem = {
  tip: string
  heading: string
  howto?: {
    label: string
    url: string
  }
  nav: NavItem[]
}

export const footerData: Record<string, footerItem> = {
  ru: {
    tip: 'Нашли ошибку? Выделите ее и нажмите Ctrl+Enter',
    heading: 'Навигация',
    howto: {
      label: 'Как читать  «Медузу» во время блокировки',
      url:
        '/feature/2022/03/04/meduza-zablokirovana-v-rossii-my-byli-k-etomu-gotovy-i-prodolzhaem-rabotat'
    },
    nav: [
      {
        title: 'Медуза',
        key: 'meduza',
        items: [
          {
            link: '/pages/contacts',
            name: 'О редакции',
            type: 'internal'
          },
          {
            link: '/pages/codex',
            name: 'Кодекс «Медузы»',
            type: 'internal'
          },
          {
            name: 'Meduza in English',
            link: '/en',
            type: 'switcher'
          },
          {
            link: '/feature/2018/05/22/kak-meduza-ispolzuet-cookie',
            name: 'Использование куки',
            type: 'internal'
          },
          {
            link:
              '/feature/2018/05/22/kak-my-obrabatyvaem-vashi-personalnye-dannye-kotorye-hranyatsya-na-meduze',
            name: 'Обработка данных',
            type: 'internal'
          },
          {
            link:
              '/cards/u-menya-est-vazhnaya-informatsiya-dlya-meduzy-no-ya-boyus-ee-peredavat-kak-sdelat-eto-po-nastoyaschemu-anonimno',
            name: 'Связаться анонимно',
            type: 'internal'
          },
          {
            link: 'https://support.meduza.io/',
            name: 'Поддержать «Медузу»',
            type: 'external'
          },
          {
            type: 'notifications',
            name: 'Подписаться на пуши'
          }
        ]
      },
      {
        title: 'Приложения',
        key: 'apps',
        items: [
          {
            link: 'https://mdza.io/jTVPVpHqgF8',
            name: 'iOS',
            type: 'external'
          },
          {
            link: 'https://mdza.io/HFRM4ny5IRU',
            name: 'Android',
            type: 'external'
          }
        ]
      },
      {
        title: 'Книги',
        key: 'books',
        items: [
          {
            link: 'https://magaz.meduza.io/',
            name: 'Магаз',
            type: 'external'
          },
          {
            link: 'https://magaz.meduza.io/info/delivery',
            name: 'Доставка книг',
            type: 'external'
          }
        ]
      },
      {
        title: 'Платформы',
        key: 'platforms',
        items: [
          {
            link: 'https://instagram.com/meduzapro',
            name: 'Инстаграм',
            type: 'external'
          },
          {
            link: 'https://t.me/meduzalive',
            name: 'Телеграм',
            type: 'external'
          },
          {
            link: 'https://www.facebook.com/themeduza',
            name: 'Фейсбук',
            type: 'external'
          },
          {
            link: 'https://twitter.com/meduzaproject',
            name: 'X (твиттер)',
            type: 'external'
          },
          {
            link: 'https://www.youtube.com/c/MeduzaPro',
            name: 'Ютьюб',
            type: 'external'
          },
          {
            link: '/feature/2020/11/23/chitayte-meduzu-tam-gde-vam-udobno',
            name: 'Все платформы',
            type: 'internal'
          }
        ]
      }
    ]
  },
  en: {
    tip: 'Found an error? Select it and press Ctrl+Enter',
    heading: 'Site Index',
    nav: [
      {
        key: 'aboutEn',
        title: 'meduza',
        items: [
          {
            link: '/en/pages/about',
            name: 'About',
            type: 'internal'
          },
          {
            link: '/en/pages/codex',
            name: 'Code of conduct',
            type: 'internal'
          },
          {
            link:
              '/en/feature/2018/05/25/how-we-process-your-personal-data-stored-by-meduza',
            name: 'Privacy notes',
            type: 'internal'
          },
          {
            link: '/en/feature/2018/05/25/how-meduza-uses-cookies',
            name: 'Cookies',
            type: 'internal'
          },
          {
            type: 'switcher',
            link: '/',
            name: 'Meduza in Russian'
          },
          {
            link: 'https://support.meduza.io/en',
            name: 'Support Meduza',
            type: 'external'
          }
        ]
      },
      {
        key: 'platforms',
        title: 'Platforms',
        items: [
          {
            link: 'https://www.facebook.com/meduzaenglish',
            name: 'Facebook',
            type: 'external'
          },
          {
            link: 'https://twitter.com/meduza_en',
            name: 'Twitter',
            type: 'external'
          },
          {
            link: 'https://www.instagram.com/meduza_en/',
            name: 'Instagram',
            type: 'external'
          },
          {
            link: '/rss/en/all',
            name: 'RSS',
            type: 'external'
          }
        ]
      },
      {
        key: 'podcasts',
        title: 'Podcast',
        items: [
          {
            link: '/en/podcasts/the-naked-pravda',
            name: 'The Naked Pravda',
            type: 'internal'
          }
        ]
      }
    ]
  }
}
