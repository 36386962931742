/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetDaily } from '@client/store/CurrentUser/currentUserActions'
import { makeClassName } from '@utils/makeClassName'

import { BeetLogo } from '../../RenderBlocks/BeetSubscription/BeetLogo'
import { SubscriptionForm } from '../SubscriptionForm'
import { ToggleSubscription } from '../ToggleSubscription'

import { locale } from './locale'
import { SOSLogo } from './SOSLogo'

import styles from './FooterBlock.module.css'

interface BriefSubscriptionProps {
  handleSubscribeToDaily: any
  handleSubscribeToSOS: any
  handleSubscribe: any
  daily: any
  sos: any
  email: any
  handleInput: any
  error: any
  lang: 'ru' | 'en'
}

export const FooterBlock: React.FC<BriefSubscriptionProps> = ({
  handleSubscribeToDaily,
  handleSubscribeToSOS,
  handleSubscribe,
  daily,
  sos,
  email,
  handleInput,
  error,
  lang
}) => {
  const [isDailySubscription, setIsDailySubsription] = useState(false)

  const local = locale[lang]
  const localBeet = locale.beet

  const dispatch = useDispatch()

  const renderSuccessMessage = (message: string, email: string) => {
    const success = email ? message.replace('{USER_EMAIL}', email) : message

    return (
      <div className={styles.success}>
        <p dangerouslySetInnerHTML={{ __html: success }} />
      </div>
    )
  }

  const handleToggleSubscription = () => {
    setIsDailySubsription(!isDailySubscription)

    dispatch(resetDaily())
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {lang === 'en' && local.tag && (
          <ToggleSubscription
            data={local}
            isDailySubscription={isDailySubscription}
            handleToggleSubscription={handleToggleSubscription}
          />
        )}

        {lang === 'ru' && local.tag && (
          <h2 className={styles.tag}>{local.tag}</h2>
        )}

        {isDailySubscription && local.title && (
          <h3 className={styles.title}>{local.title}</h3>
        )}

        {lang === 'en' && !isDailySubscription && localBeet.hasLogo && (
          <div className={styles.imageBeet}>
            <BeetLogo />
          </div>
        )}

        {lang === 'ru' && local.hasLogo && (
          <div className={styles.image}>
            <SOSLogo />
          </div>
        )}

        <div
          className={makeClassName([
            [styles.textRu, lang === 'ru'],
            [styles.textEn, lang === 'en']
          ])}
          dangerouslySetInnerHTML={
            isDailySubscription || lang === 'ru'
              ? { __html: local.text }
              : { __html: localBeet.text }
          }
        />

        <SubscriptionForm
          data={local}
          lang={lang}
          handleSubscribeToDaily={handleSubscribeToDaily}
          handleSubscribeToSOS={handleSubscribeToSOS}
          handleSubscribe={handleSubscribe}
          isDailySubscription={isDailySubscription}
          email={email}
          error={error}
          daily={daily}
          sos={sos}
          handleInput={handleInput}
          renderSuccessMessage={renderSuccessMessage}
        />
      </div>
    </div>
  )
}
