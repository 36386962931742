import React, { memo } from 'react'
import { AudioPlayerMobile } from '@common/AudioPlayer'

import styles from './AudioPlayerControls.module.css'

interface ControlsProps {
  url: string
}

const AudioPlayerControlsComponent: React.FC<ControlsProps> = ({ url }) => {

  return (
    <div className={styles.root}>
      <div className={styles.control}>
        <AudioPlayerMobile url={url} />
      </div>
    </div>
  )
}

export const AudioPlayerControls = memo(AudioPlayerControlsComponent)
