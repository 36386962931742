import React, { useRef } from 'react'
import { makeClassName } from '@utils/makeClassName'

import styles from './DownloadIcon.module.css'

interface DownloadIconProps {
  styleContext?: string
  theme?: string
  url: string | null
}

export const DownloadIcon: React.FC<DownloadIconProps> = ({
  styleContext,
  theme,
  url
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null)

  return (
    <a
      ref={linkRef}
      className={makeClassName([
        [[styles.button], true],
        [[styles.light], theme === 'light'],
        [[styles.dark], theme === 'dark'],
        [[styles[styleContext]], !!styleContext]
      ])}
      target="_blank"
      rel="noreferrer"
      download={true}
      href={url}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <g opacity="1">
          <circle
            cx="15"
            cy="15"
            r="10.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 19.5L15 10.5M15 19.5L18.5 17.2257M15 19.5L11.5 17.2257"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </a>
  )
}
