import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// import { DFPBanner } from '@client/components/Common/DFPBanner'
import { Block } from '@client/types'
import {
  EpisodeMaterialContent,
  RichMaterialContent,
  SimpleMaterialContent
} from '@client/types'
import { generateChapters } from '@client/utils/generateChapters'
import { BookmarkIcon } from '@common/BookmarkIcon'
import { useLightbox } from '@common/Lightbox'
import { RenderBlocks } from '@common/RenderBlocks'
import { useTrackScroll } from '@hooks/useTrackScroll'
import { getApp, getIsMobile } from '@selectors/appSelectors'
import { getCurrentUser, getLang } from '@selectors/currentUserSelectors'
import { makeClassName } from '@utils/makeClassName'
import { tryPushBanner } from '@utils/tryPushBanner'

import { SharePanel } from '../MaterialComponents/SharePanel'

import { AdvPanel } from './AdvPanel'

import styles from './GeneralMaterial.module.css'

export interface GeneralMaterialProps {
  material: RichMaterialContent | SimpleMaterialContent | EpisodeMaterialContent
}

export const GeneralMaterial: React.FC<GeneralMaterialProps> = ({
  material
}) => {
  if (material?.table_of_contents?.enabled) {
    material = generateChapters(null, material.layout, material)
  }

  const lightBox = useLightbox()
  const isMobile = useSelector(getIsMobile)
  const lang = useSelector(getLang)

  const { isEmbedded } = useSelector(getApp)

  const [isRendered, setIsRendered] = useState(false)

  const {
    content,
    layout,
    container: { display, mods = [], cover, mobile_cover: mobileCover, half },
    banners,
    url
  } = material

  const { ref, isRead } = useTrackScroll(url)
  const { episode_history_shelf } = useSelector(getCurrentUser)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  const isListened =
    episode_history_shelf && episode_history_shelf[material.url] > 0.75

  const modifiers: string[] = [layout]

  if (display) modifiers.push(display)
  if (mods) modifiers.splice(mods.length, 0, ...mods)

  const classes = [
    [styles.root, true],
    [styles.hasCover, !!cover],
    [styles.hasMobileCover, !!mobileCover],
    [styles.halfCover, !!half]
  ].concat(
    Array.isArray(modifiers)
      ? modifiers.map((ctx) => [styles[ctx], !!ctx && styles[ctx]])
      : [[styles[modifiers], !!modifiers && !!styles[modifiers]]]
  )

  const shouldTryPushBanner =
    banners.top && !isEmbedded && layout === 'rich' && lang === 'en'

  const withBanners = display === 'default' && banners.right && lang === 'en'

  const isDark = mods?.includes('dark')
  const isOneImage = mods?.includes('picture')

  const shouldRenderShare = lang === 'en' && !isOneImage

  return (
    <div className={makeClassName(classes)} ref={ref}>
      <div className={styles.container}>
        <div
          className={makeClassName([
            [[styles.head], true],
            [[styles.headInEpisode], layout === 'episode']
          ])}
        >
          {material.layout === 'episode' && (
            <div className={styles.materialHeader}>
              {content.head.map((block: Block) => (
                <RenderBlocks
                  url={material.url}
                  key={block.id}
                  block={block}
                  styleContext="isInDynamicBlock"
                  isRead={isRead}
                  isListened={isListened}
                  type="isInMaterial"
                />
              ))}
            </div>
          )}
          {material.layout !== 'episode' && (
            <div className={styles.materialHeader}>
              {content.head.map((block: Block) => (
                <RenderBlocks
                  url={material.url}
                  key={block.id}
                  block={block}
                  styleContext={modifiers}
                  isRead={isRead}
                  isListened={isListened}
                />
              ))}

              {layout !== 'simple' && (
                <div className={styles.bookmark}>
                  <BookmarkIcon url={url} />
                </div>
              )}

              {shouldRenderShare && (
                <div className={styles.share}>
                  <SharePanel
                    place="underHeader"
                    theme={isDark ? 'dark' : 'light'}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {isRendered && isMobile && banners.top && !isEmbedded && <AdvPanel />}

        <div className={styles.body}>
          <div className={styles.article}>
            {tryPushBanner(content.blocks, shouldTryPushBanner).map((block) => (
              <RenderBlocks
                url={url}
                key={block.id}
                block={block as Block}
                styleContext={modifiers}
                lightBox={lightBox}
              />
            ))}
          </div>
          {/* {withBanners && (
            <div className={styles.aside}>
              <DFPBanner lang={lang} />
            </div>
          )} */}
        </div>
      </div>
    </div>
  )
}
