import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AudioBlock, RootState } from '@client/types'
import { EpisodesData } from '@client/types'
import { ALERT_TEXTS, TYPES, useAlert } from '@common/Alert'
import {
  getEpisodeData,
  getPlayButtonProps
} from '@selectors/audioPlayerSelectors'
import {
  requestPause,
  requestPlay
} from '@store/AudioPlayer/audioPlayerActions'
import { makeClassName } from '@utils/makeClassName'

import { SvgSymbol } from '@meduza/ui-kit-2'
import { SvgSymbolStyleContexts } from '@meduza/ui-kit-2/dist/SvgSymbol/SvgSymbol.types'

import { PlayerStatus } from '../constants'

import styles from './PlayButton.module.css'

/*
 * Универсальная кнопка Play для всех видов плееров
 */

interface PlayButtonProps {
  episodeUrl: string
  styleContext: string
  podcasts?: string
  block?: AudioBlock | EpisodesData
  stateEpisode?: EpisodesData
  theme?: string
}

const getEpisodeDataFromBlock = (block: AudioBlock | EpisodesData) => {
  if ('data' in block) {
    return {
      audio: block.data.audio,
      player: block.data.audio.player,
      image: block.data.audio.image
    }
  } else {
    return {
      audio: block.audio,
      player: block.player,
      image: block.image
    }
  }
}

export const PlayButton: React.FC<PlayButtonProps> = ({
  episodeUrl,
  styleContext,
  podcasts,
  block,
  stateEpisode,
  theme = 'light'
}) => {
  const dispatch = useDispatch()
  const { show } = useAlert()

  const episode = useSelector((state: RootState) =>
    getEpisodeData(state, { url: episodeUrl, podcasts })
  )

  const { status, time } = useSelector((state: RootState) =>
    getPlayButtonProps(state, episodeUrl)
  )

  useEffect(() => {
    if (status === PlayerStatus.ERROR) {
      show(ALERT_TEXTS.ON_PLAYER_ERROR, {
        type: TYPES.ERROR
      })
    }
  }, [show, status])

  const handleClick = () => {
    if (status === PlayerStatus.LOADING) {
      return
    }

    if (status === PlayerStatus.PLAYING) {
      dispatch(requestPause({ episodeUrl }))
    } else {
      const {
        audio,
        player: { blocks },
        image
      } = stateEpisode || episode || (block && getEpisodeDataFromBlock(block))

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const episodeData: any = { audio, blocks, image }

      dispatch(requestPlay({ episodeUrl, episodeData }))
    }
  }

  const type = status === PlayerStatus.PLAYING ? 'pause' : 'play'
  const showPlayPauseIcon = [PlayerStatus.LOADING].indexOf(status) === -1
  const showLoadingIcon = [PlayerStatus.LOADING].indexOf(status) > -1

  return (
    <button
      className={makeClassName([
        [styles.root, true],
        [styles.dark, theme === 'dark'],
        [styles[type], !!type && !!styles[type]],
        [styles[styleContext], !!styleContext && !!styles[styleContext]]
      ])}
      aria-label={`${type} button`}
      onClick={handleClick}
      data-unplayed={time === null || 0}
      type="button"
    >
      <span
        className={makeClassName([
          [styles.control, true],
          [styles.controlPlay, type === 'play'],
          [styles.controlPause, type === 'pause']
        ])}
        tabIndex={-1}
      >
        {showPlayPauseIcon && styleContext !== 'isInInstantPlayer' && (
          <SvgSymbol
            icon={type}
            size="medium"
            styleContext={styleContext as SvgSymbolStyleContexts}
          />
        )}
        {showPlayPauseIcon && styleContext === 'isInInstantPlayer' && (
          <>
            {type === 'play' && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="30"
                viewBox="0 0 28 30"
                fill="none"
              >
                <g opacity="0.25">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.78801 28.3023C4.45472 29.0598 2.79999 28.0968 2.79999 26.5633V3.43665C2.79999 1.90318 4.45472 0.940189 5.78801 1.69774L26.1395 13.2611C27.4888 14.0277 27.4888 15.9723 26.1395 16.7389L5.78801 28.3023Z"
                    fill="black"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            )}
            {type === 'pause' && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <g opacity="0.25">
                  <rect x="4" width="7" height="28" rx="1" fill="black" />
                  <rect x="17" width="7" height="28" rx="1" fill="black" />
                </g>
              </svg>
            )}
          </>
        )}
      </span>

      {showLoadingIcon && (
        <div className={styles.loader}>
          <svg className={styles.loaderIcon} viewBox="0 0 22 22">
            <circle
              className={styles.loaderDash}
              cx="11"
              cy="11"
              r="8"
              fill="none"
              strokeWidth="2"
            />
          </svg>
        </div>
      )}
    </button>
  )
}
