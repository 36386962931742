import React from 'react'

import styles from './FooterBlock.module.css'

export const SOSLogo = () => {
  const quantityOfStars = 3
  const arrayOfStars = Array.from({ length: quantityOfStars }, (_, i) => i)

  return (
    <div className={styles.sosLogo}>
      {arrayOfStars.map((star, index) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="39"
          height="40"
          viewBox="0 0 39 40"
          fill="none"
          key={star + index}
        >
          <path
            d="M19.3294 0L22.2646 9.64053L29.7796 3.16962L27.2031 12.919L36.912 11.6722L29.6418 18.435L38.462 22.8081L28.8064 24.4373L33.9375 33.0419L24.962 29.0201L24.7751 39.1244L19.3294 30.7286L13.8836 39.1244L13.6967 29.0201L4.7212 33.0419L9.85233 24.4373L0.196745 22.8081L9.01688 18.435L1.74676 11.6722L11.4556 12.919L8.87912 3.16962L16.3941 9.64053L19.3294 0Z"
            fill="white"
          />
        </svg>
      ))}
    </div>
  )
}
