export const generateGradientEpisode = (
  color: string,
  steps: 5,
  angle: number
) => {
  const gradientSteps = {
    5: [
      [0, 1.6],
      [0.2, 22.3],
      [0.5, 42.5],
      [0.8, 71.1],
      [1, 96.9]
    ]
  }

  const gradient = gradientSteps[steps].map(
    (i) => `rgba(${color}, ${i[0]}) ${i[1]}%`
  )

  return `linear-gradient(${angle}deg, ${gradient})`
}
