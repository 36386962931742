import {
  FIREBASE_FUNCTIONS,
  MDZ_AUTH_FIREBASE_TOKEN_KEY,
  MDZ_FIREBASE_USER_DATA
} from '@client/constants'
import { COLLECTIONS } from '@client/enums'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { callToAction } from '@store/App/appActions'
import {
  callToPushNotifications,
  deleteUser,
  emailLogin,
  getFeedbackToken,
  loginGoogle,
  logout,
  markMaterialRead,
  sendEmail,
  sendMessage,
  sendReaction,
  sendTextError,
  setFirebaseTopicStatus,
  subscribeToDaily,
  subscribeToSOS,
  toggleBookmark,
  updateUserData,
  userIsLoggedIn
} from '@store/CurrentUser/currentUserActions'
import { CurrentUserState } from '@store/CurrentUser/currentUserReducer'
import { showModal } from '@store/Modal/modalActions'
import { handleError } from '@utils/handleError'
import { EventChannel } from 'redux-saga'
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery
} from 'redux-saga/effects'

import * as api from '../api'
import * as firebase from '../firebase'
/*
 После логина через Google кладет данные о юзере в файрбейс
 */

export function* loginGoogleSaga() {
  try {
    const response = yield call(firebase.loginGoogle)
    yield call(
      [localStorage, localStorage.setItem],
      MDZ_AUTH_FIREBASE_TOKEN_KEY,
      response.credential.accessToken
    )

    yield call(
      [localStorage, localStorage.setItem],
      MDZ_FIREBASE_USER_DATA,
      JSON.stringify(response.user)
    )

    yield put(loginGoogle.success(response.user))
  } catch (err) {
    yield call(handleError, err, loginGoogle.failure.type)
    yield put(loginGoogle.failure())
  }
}

/*
 Firebase: отправляет magic-link юзеру и кладет его email в localStorage
 */

export function* sendEmailSaga({
  payload: { backRoute, email }
}: ReturnType<typeof sendEmail.request>) {
  try {
    const actionCodeSettings = {
      url: `${window.location.origin}${backRoute}`,
      handleCodeInApp: true
    }

    yield call(firebase.sendEmail, email, actionCodeSettings)
    yield call([localStorage, localStorage.setItem], 'emailForSignIn', email)
    yield put(sendEmail.success())
  } catch (err) {
    yield call(handleError, err, sendEmail.failure.type)
    yield put(sendEmail.failure())
  }
}

export function* logoutSaga() {
  try {
    yield call(firebase.logout)
    yield call(
      [localStorage, localStorage.removeItem],
      MDZ_AUTH_FIREBASE_TOKEN_KEY
    )
    yield call([localStorage, localStorage.removeItem], MDZ_FIREBASE_USER_DATA)
    yield call([localStorage, localStorage.removeItem], 'bookmarks')
    yield call([localStorage, localStorage.removeItem], 'paymentData')
    yield put(logout.success())
  } catch (err) {
    yield call(handleError, err, logout.failure.type)
    yield put(logout.failure())
  }
}

export function* deleteUserSaga() {
  try {
    yield call(firebase.callFunctions, FIREBASE_FUNCTIONS.DELETE_USER)
    yield call(logoutSaga)
    yield put(deleteUser.success())
  } catch (err) {
    yield call(handleError, err, deleteUser.failure.type)
    yield put(deleteUser.failure())
  }
}

/*
 Firebase: отправляет сообщение в help scout из меню юзера
 */

export function* sendMessageSaga({
  payload
}: ReturnType<typeof sendMessage.request>) {
  try {
    yield call(firebase.callFunctions, FIREBASE_FUNCTIONS.SEND_MESSAGE, payload)
    yield put(sendMessage.success())
  } catch (err) {
    yield call(handleError, err, sendMessage.failure.type)
    yield put(sendMessage.failure())
  }
}

/*
 запрашивает токен для отправки "напишите нам" (просто защита)
 */

export function* getFeedbackTokenSaga() {
  try {
    const response = yield call(api.getFeedbackToken)
    yield put(getFeedbackToken.success({ response }))
  } catch (err) {
    yield call(handleError, err, getFeedbackToken.failure.type)
    yield put(getFeedbackToken.failure())
  }
}

/*
 подписывает на рассылку
 */

export function* subscribeToDailySaga({
  payload: { email, token, lang }
}: ReturnType<typeof subscribeToDaily.request>) {
  try {
    const params = new window.URLSearchParams()
    params.append('email', email)
    params.append('token', token)

    const response = yield call(api.subscribeToDaily, params, lang)
    yield put(subscribeToDaily.success({ response }))
  } catch (err) {
    yield call(handleError, err, subscribeToDaily.failure.type)
    yield put(subscribeToDaily.failure())
  }
}

/*
 подписывает на рассылку SOS
 */

export function* subscribeToSOSSaga({
  payload: { email, token, lang }
}: ReturnType<typeof subscribeToSOS.request>) {
  try {
    const params = new window.URLSearchParams()
    params.append('email', email)
    params.append('token', token)

    const response = yield call(api.subscribeToSOS, params, lang)
    yield put(subscribeToSOS.success({ response }))
  } catch (err) {
    yield call(handleError, err, subscribeToSOS.failure.type)
    yield put(subscribeToSOS.failure())
  }
}

/*
 Firebase: пометка прочитанного материала (75%)
 */

export function* markMaterialReadSaga({
  payload
}: ReturnType<typeof markMaterialRead>) {
  const currentUser: CurrentUserState = yield select(getCurrentUser)
  const { firebase_user } = currentUser
  if (firebase_user) {
    yield call(firebase.markMaterialRead, firebase_user.uid, payload)
  }
}

/*
 Firebase: добавление/удаление закладок
 */

export function* toggleBookmarkSaga({
  payload
}: ReturnType<typeof toggleBookmark>) {
  const currentUser: CurrentUserState = yield select(getCurrentUser)
  const { firebase_user, bookmarks } = currentUser
  if (firebase_user) {
    yield call(firebase.toggleBookmark, firebase_user.uid, bookmarks, payload)
  }
}

/*
 Firebase: после удачного логина запускает листенеры
 */

export function* loginSuccessSaga({
  payload: user
}: ReturnType<typeof loginGoogle.success>) {
  const firebaseChannel = yield call(firebase.createFirestoreSubscriber, user, [
    COLLECTIONS.HISTORY,
    COLLECTIONS.BOOKMARKS,
    COLLECTIONS.EPISODE_HISTORY_SHELF
  ])

  yield fork(watchLogoutSaga, firebaseChannel)
  yield call(watchFirebaseData, firebaseChannel)
}

/*
 отправляет опечатку
 */

export function* sendTextErrorSaga({
  payload: { pathname, expanded, text, comment }
}: ReturnType<typeof sendTextError.request>) {
  try {
    const data = {
      comment,
      key: pathname,
      before: expanded.before,
      after: expanded.after,
      error: text
    }
    yield call(api.sendTextError, data)
    yield put(sendTextError.success())
  } catch (err) {
    yield call(handleError, err, sendTextError.failure.type)
    yield put(sendTextError.failure())
  }
}

/*
 Отправляет "напишите нам"
 */

export function* sendReactionSaga({
  payload: { key, email, csrfToken, tag, text, type }
}: ReturnType<typeof sendReaction.request>) {
  try {
    const data = {
      csrf: csrfToken,
      payload: {
        key,
        email,
        text,
        tag: tag || '',
        type: type || 'idea'
      }
    }
    yield call(api.sendReaction, data)
    yield put(sendReaction.success())
  } catch (err) {
    yield call(handleError, err, sendReaction.failure.type)
    yield put(sendReaction.failure())
  }
}

/*
 Firebase: подписывает на пуш-уведомления
 */

export function* callToPushNotificationsSaga({
  payload: { topic, action },
  payload
}: ReturnType<typeof callToPushNotifications.request>) {
  try {
    yield call(api.callToPushNotifications, payload)

    const status = action === 'subscribe' ? 'subscribed' : 'unsubscribed'

    yield call(
      [localStorage, localStorage.setItem],
      `mdzFirebasePushChannel:${topic}`,
      status
    )
    yield put(setFirebaseTopicStatus({ topic, status }))
    yield put(callToPushNotifications.success())
  } catch (err) {
    yield call(handleError, err, callToPushNotifications.failure.type)
    yield put(callToPushNotifications.failure())
  }
}

/*
 Firebase: добавляет/удаляет закладки
 Если юзер кликнул на закладку незалогиненным - открывает окно логина
 и кидает закладку в localStorage. При удачном логине добавляет ее в закладки
 */

export function* callToActionSaga({
  payload: { service }
}: ReturnType<typeof callToAction>) {
  if (service === 'bookmark') {
    const currentUser: CurrentUserState = yield select(getCurrentUser)
    if (currentUser.firebase_user && currentUser.last_opened_material) {
      yield call(
        firebase.toggleBookmark,
        currentUser.firebase_user.uid,
        currentUser.bookmarks,
        currentUser.last_opened_material.url
      )
    } else {
      if (currentUser.last_opened_material) {
        yield call(
          [localStorage, localStorage.setItem],
          'saved_bookmark',
          currentUser.last_opened_material.url
        )
        yield put(
          showModal({
            type: 'loginBookmark',
            coordinates: 0
          })
        )
      }
    }
  }
}

export function* watchLogoutSaga(firebaseChannel: EventChannel<unknown>) {
  yield take(logout.success.type)
  yield call(firebaseChannel.close)
}

/*
 Firebase: следит за данными real-time
 */

export function* watchFirebaseData(firebaseChannel: EventChannel<unknown>) {
  while (true) {
    const { message, data } = yield take(firebaseChannel)

    switch (message) {
      case COLLECTIONS.EPISODE_HISTORY_SHELF:
        {
          yield put(
            updateUserData({
              data,
              type: COLLECTIONS.EPISODE_HISTORY_SHELF
            })
          )
        }
        break
      case COLLECTIONS.HISTORY:
        {
          yield put(
            updateUserData({
              data,
              type: COLLECTIONS.HISTORY
            })
          )
        }
        break
      case COLLECTIONS.BOOKMARKS: {
        if (data && Object.keys(data).length === 0) {
          localStorage.removeItem('bookmarks')
        }

        yield put(
          updateUserData({
            data,
            type: COLLECTIONS.BOOKMARKS
          })
        )
      }
    }
  }
}

/*
 Добавляет закладку после удачного логина (см. callToActionSaga)
 и убирает ее из localStorage
 */

export function* updateUserDataSaga({
  payload: { data, type }
}: ReturnType<typeof updateUserData>) {
  if (type === COLLECTIONS.BOOKMARKS) {
    const articleKey = yield call(
      [localStorage, localStorage.getItem],
      'saved_bookmark'
    )
    if (articleKey) {
      if (!data[articleKey]) {
        yield put(toggleBookmark(articleKey))
      }

      yield call([localStorage, localStorage.removeItem], 'saved_bookmark')
    }
  }
}

export default function* appSaga() {
  yield all([
    takeEvery(loginGoogle.request.type, loginGoogleSaga),
    takeEvery(sendEmail.request.type, sendEmailSaga),
    takeEvery(
      [loginGoogle.success.type, emailLogin, userIsLoggedIn.type],
      loginSuccessSaga
    ),
    takeEvery(logout.request.type, logoutSaga),
    takeEvery(deleteUser.request.type, deleteUserSaga),
    takeEvery(sendMessage.request.type, sendMessageSaga),
    takeEvery(getFeedbackToken.request.type, getFeedbackTokenSaga),
    takeEvery(subscribeToDaily.request.type, subscribeToDailySaga),
    takeEvery(subscribeToSOS.request.type, subscribeToSOSSaga),
    takeEvery(sendTextError.request.type, sendTextErrorSaga),
    takeEvery(sendReaction.request.type, sendReactionSaga),
    takeEvery(markMaterialRead.type, markMaterialReadSaga),
    takeEvery(toggleBookmark.type, toggleBookmarkSaga),
    takeEvery(callToAction.type, callToActionSaga),
    takeEvery(
      callToPushNotifications.request.type,
      callToPushNotificationsSaga
    ),
    takeEvery(updateUserData.type, updateUserDataSaga)
  ])
}
