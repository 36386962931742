import React, { useCallback, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUS } from '@client/enums'
import { ImageUrls, Newsletter } from '@client/types'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import {
  resetStatuses,
  subscribeToDaily,
  subscribeToSOS
} from '@store/CurrentUser/currentUserActions'
import { validateEmail } from '@utils/validateEmail'

import { DailyBlock } from './DailyBlock'
import { FooterBlock } from './FooterBlock'
import { SignalBlock } from './SignalBlock'

interface BriefSubscriptionProps {
  correlation?: keyof ImageUrls
  variant?: 'footer' | 'screen'
}

export const BriefSubscriptionContainer: React.FC<BriefSubscriptionProps> = ({
  correlation,
  variant
}) => {
  const dispatch = useDispatch()

  const { executeRecaptcha } = useGoogleReCaptcha()

  const {
    lang,
    daily,
    sos,
    subscribeToDailyStatus,
    subscribeToSOSStatus
  } = useSelector(getCurrentUser)

  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false)
    setEmail(e.target.value)
  }

  const handleSubscribeToDaily = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()

      if (!executeRecaptcha) {
        return
      }

      if (!validateEmail(email)) {
        return setError(true)
      }

      const token = await executeRecaptcha('homepage')

      if (token) {
        setError(false)
        dispatch(
          subscribeToDaily.request({
            token: token,
            email,
            lang: `daily/${lang}` as Newsletter
          })
        )
      }
    },
    [dispatch, email, executeRecaptcha, lang]
  )

  // SOS Subscription functions starts //
  const handleSubscribeToSOS = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()

      if (!executeRecaptcha) {
        return
      }

      if (!validateEmail(email)) {
        return setError(true)
      }

      const token = await executeRecaptcha('homepage')

      if (token) {
        setError(false)
        dispatch(
          subscribeToSOS.request({
            token: token,
            email,
            lang: `sos/${lang}` as Newsletter
          })
        )
      } else {
        setError(true)
      }
    },
    [dispatch, email, executeRecaptcha, lang]
  )
  // SOS Subscription functions ends //

  // Beet Subscription functions starts //
  const handleSubscribe = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()

      if (!executeRecaptcha) {
        return
      }

      if (!validateEmail(email)) {
        return setError(true)
      }

      const token = await executeRecaptcha('homepage')

      if (token) {
        setError(false)
        dispatch(
          subscribeToDaily.request({
            token: token,
            email,
            lang: 'beet'
          })
        )
      } else {
        setError(true)
      }
    },
    [dispatch, email, executeRecaptcha]
  )
  // Beet Subscription functions ends //

  useEffect(() => {
    if (
      subscribeToDailyStatus === REQUEST_STATUS.SUCCESS ||
      subscribeToDailyStatus === REQUEST_STATUS.FAILURE ||
      subscribeToSOSStatus === REQUEST_STATUS.SUCCESS ||
      subscribeToSOSStatus === REQUEST_STATUS.FAILURE
    ) {
      dispatch(resetStatuses())
    }
  }, [dispatch, subscribeToDailyStatus, subscribeToSOSStatus])

  if (variant === 'footer') {
    return (
      <FooterBlock
        lang={lang}
        handleSubscribeToDaily={handleSubscribeToDaily}
        handleSubscribeToSOS={handleSubscribeToSOS}
        handleSubscribe={handleSubscribe}
        daily={daily}
        sos={sos}
        email={email}
        handleInput={handleInput}
        error={error}
      />
    )
  }

  if (lang === 'en') {
    return (
      <DailyBlock
        correlation={correlation}
        handleSubscribeToDaily={handleSubscribeToDaily}
        daily={daily}
        email={email}
        handleInput={handleInput}
        error={error}
      />
    )
  }

  return (
    <SignalBlock
      correlation={correlation}
      handleSubscribeToDaily={handleSubscribeToDaily}
      daily={daily}
      email={email}
      handleInput={handleInput}
      error={error}
    />
  )
}
