import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { EPISODES_PER_PAGE, EPISODES_START_PAGE } from '@client/constants'
import { EpisodesData, Lang, Pagination } from '@client/types'
import { loadMoreEpisodesPodcasts } from '@client/utils/loadMoreEpisodesPodcasts'
import { ALERT_TEXTS, TYPES, useAlert } from '@common/Alert'
import { PlayButton, Progress } from '@common/AudioPlayer'
import { ProgressTypeEnums } from '@common/AudioPlayer/constants'
import { BookmarkIcon } from '@common/BookmarkIcon'
import { Link } from '@common/Link'

import { Button, Meta, MetaItem, Timestamp } from '@meduza/ui-kit-2'
import { ButtonStates } from '@meduza/ui-kit-2/dist/Button/Button.types'

import { DownloadIcon } from '../../DownloadIcon'

import { EpisodesLocalization } from './EpisodesLocalization'

import styles from './Episodes.module.css'

interface EpisodesProps {
  data: EpisodesData[]
  shouldUsePagination: boolean
  pagination?: Pagination
  lang: Lang
  isPodcastSeason?: boolean
}

/*
 * Список эпизодов в материале одного вида подкастов
 */

export const Episodes: React.FC<EpisodesProps> = ({
  data,
  shouldUsePagination,
  pagination,
  lang,
  isPodcastSeason
}) => {
  const { pathname } = useLocation()
  const [page, setPage] = useState(EPISODES_START_PAGE)

  const { show } = useAlert()

  const shouldHideEpisodes = shouldUsePagination
    ? pagination.total > EPISODES_PER_PAGE * page
    : false

  const episodesList = shouldHideEpisodes
    ? data.slice(0, EPISODES_PER_PAGE)
    : data

  const [episodes, setEpisodes] = useState(episodesList)
  const [isLoadMore, setIsLoadMore] = useState(shouldHideEpisodes)
  const [state, setState] = useState<ButtonStates>('isDefault')
  const [isError, setIsError] = useState(false)

  const podcasts = pathname.substring(1)
  const t = EpisodesLocalization[lang]

  const handleMoreEpisodes = () => {
    loadMoreEpisodesPodcasts({
      page,
      setPage,
      episodes,
      setEpisodes,
      setState,
      setIsError,
      podcasts
    })
  }

  useEffect(() => {
    if (!shouldUsePagination) {
      setEpisodes(data)
      return
    }
    if (pagination.total <= EPISODES_PER_PAGE * page) {
      setIsLoadMore(false)
    }
  }, [page, pagination?.total, shouldUsePagination, data])

  useEffect(() => {
    if (isError) {
      show(ALERT_TEXTS.ON_ERROR, {
        type: TYPES.ERROR
      })
    }
    setIsError(false)
  }, [show, isError])

  return (
    <div className={styles.root}>
      <ul className={styles.list}>
        {episodes.map((episode) => (
          <li className={styles.episode} key={episode.url}>
            <Link to={`/${episode.url}`}>
              <h3>{episode.title}</h3>
              <p>{episode.subtitle}</p>
            </Link>
            <div className={styles.player}>
              <div className={styles.mainPanel}>
                <div className={styles.play}>
                  <PlayButton
                    episodeUrl={episode.url}
                    styleContext="isInPlaylist"
                    podcasts={podcasts}
                    block={isPodcastSeason ? episode : undefined}
                    stateEpisode={episode}
                  />
                  <Progress
                    episodeUrl={episode.url}
                    duration={episode.audio.mp3_duration}
                    type={ProgressTypeEnums.CIRCLE}
                    styleContext="isInPlaylist"
                  />
                </div>
                <div className={styles.meta}>
                  <Meta styleContext="episode">
                    <MetaItem bullets>
                      {episode.audio.mp3_duration_in_words}
                    </MetaItem>
                    <MetaItem bullets>
                      <Timestamp
                        publishedAt={episode.published_at}
                        type="fromNow"
                        locale={lang}
                      />
                    </MetaItem>
                  </Meta>
                </div>
              </div>
              <div className={styles.additionalPanel}>
                <DownloadIcon
                  styleContext="isInEpisodes"
                  theme="light"
                  url={episode.audio.mp3_url}
                />
                <div className={styles.bookmark}>
                  <BookmarkIcon
                    theme="light"
                    styleContext="isInEpisode"
                    url={episode.url || episode.audio.url}
                  />
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {isLoadMore && (
        <Button state={state} onClick={handleMoreEpisodes}>
          {t.more}
        </Button>
      )}
    </div>
  )
}
