import { REQUEST_STATUS } from '@client/enums'
import {
  HistoryElement,
  Lang,
  MaterialContent,
  PaymentHistoryElement,
  ScreenContent
} from '@client/types'
import { createReducer } from '@reduxjs/toolkit'
import {
  callToPushNotifications,
  deleteUser,
  emailLogin,
  getFeedbackToken,
  loginGoogle,
  logout,
  resetDaily,
  resetStatuses,
  sendEmail,
  sendMessage,
  sendReaction,
  sendTextError,
  setFirebasePushToken,
  setFirebaseTopicStatus,
  setLang,
  subscribeToDaily,
  subscribeToSOS,
  updateUserData,
  userIsLoggedIn
} from '@store/CurrentUser/currentUserActions'
import { fetchMaterial } from '@store/Materials/materialsActions'
import { hideModal } from '@store/Modal/modalActions'
import { fetchSpecials } from '@store/Screens/screensActions'
import firebase from 'firebase/compat/app'

export interface CurrentUserState {
  daily: {
    subscribed: boolean
    error: boolean
  }
  sos: {
    subscribed: boolean
    error: boolean
  }
  lang: Lang
  last_opened_material: MaterialContent | null
  last_opened_special: ScreenContent | null
  firebase_token: string | null
  feedback_token: string | null
  firebase_topics: Record<string, string>
  firebase_user: firebase.User | null
  episode_history_shelf: Record<string, number>
  bookmarks: Record<string, HistoryElement>
  history: Record<string, HistoryElement>
  data:
    | {
        payment_history: Record<string, PaymentHistoryElement>
        stripe_customer_id: string
        subscription_status: 'active' | 'trialing'
      }
    | Record<string, unknown>
  subscribeToDailyStatus: REQUEST_STATUS
  subscribeToSOSStatus: REQUEST_STATUS
  feedbackTokenStatus: REQUEST_STATUS
  logoutStatus: REQUEST_STATUS
  deleteStatus: REQUEST_STATUS
  googleLoginStatus: REQUEST_STATUS
  emailSendStatus: REQUEST_STATUS
  deleteUserStatus: REQUEST_STATUS
  sendMessageStatus: REQUEST_STATUS
  callToPushNotificationsStatus: REQUEST_STATUS
  sendTextErrorStatus: REQUEST_STATUS
  sendReactionStatus: REQUEST_STATUS
}

const initialState: CurrentUserState = {
  daily: {
    subscribed: false,
    error: false
  },
  sos: {
    subscribed: false,
    error: false
  },
  lang: 'ru',
  last_opened_material: null,
  last_opened_special: null,
  firebase_token: null,
  feedback_token: null,
  firebase_topics: {},
  firebase_user: null,
  episode_history_shelf: {},
  bookmarks: {},
  history: {},
  data: {},
  subscribeToDailyStatus: REQUEST_STATUS.IDLE,
  subscribeToSOSStatus: REQUEST_STATUS.IDLE,
  feedbackTokenStatus: REQUEST_STATUS.IDLE,
  logoutStatus: REQUEST_STATUS.IDLE,
  deleteStatus: REQUEST_STATUS.IDLE,
  googleLoginStatus: REQUEST_STATUS.IDLE,
  emailSendStatus: REQUEST_STATUS.IDLE,
  deleteUserStatus: REQUEST_STATUS.IDLE,
  sendMessageStatus: REQUEST_STATUS.IDLE,
  callToPushNotificationsStatus: REQUEST_STATUS.IDLE,
  sendTextErrorStatus: REQUEST_STATUS.IDLE,
  sendReactionStatus: REQUEST_STATUS.IDLE
}

export const userReducer = createReducer(initialState, (builder) => {
  const reset = (state: CurrentUserState) => {
    state.subscribeToDailyStatus = REQUEST_STATUS.IDLE
    state.subscribeToSOSStatus = REQUEST_STATUS.IDLE
    state.feedbackTokenStatus = REQUEST_STATUS.IDLE
    state.logoutStatus = REQUEST_STATUS.IDLE
    state.deleteStatus = REQUEST_STATUS.IDLE
    state.googleLoginStatus = REQUEST_STATUS.IDLE
    state.emailSendStatus = REQUEST_STATUS.IDLE
    state.deleteUserStatus = REQUEST_STATUS.IDLE
    state.sendMessageStatus = REQUEST_STATUS.IDLE
    state.callToPushNotificationsStatus = REQUEST_STATUS.IDLE
    state.sendTextErrorStatus = REQUEST_STATUS.IDLE
    state.sendReactionStatus = REQUEST_STATUS.IDLE
  }
  builder
    .addCase(loginGoogle.request, (state) => {
      reset(state)
      state.googleLoginStatus = REQUEST_STATUS.LOADING
      state.deleteUserStatus = REQUEST_STATUS.IDLE
    })
    .addCase(loginGoogle.failure, (state) => {
      state.googleLoginStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(loginGoogle.success, (state, { payload: user }) => {
      state.googleLoginStatus = REQUEST_STATUS.SUCCESS
      state.firebase_user = user
    })
    .addCase(sendEmail.request, (state) => {
      reset(state)
      state.emailSendStatus = REQUEST_STATUS.LOADING
    })
    .addCase(sendEmail.failure, (state) => {
      state.emailSendStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(sendEmail.success, (state) => {
      state.emailSendStatus = REQUEST_STATUS.SUCCESS
    })
    .addCase(emailLogin, (state, { payload: user }) => {
      reset(state)
      state.firebase_user = user
    })
    .addCase(userIsLoggedIn, (state, { payload: user }) => {
      state.firebase_user = user
    })
    .addCase(logout.request, (state) => {
      reset(state)
      state.logoutStatus = REQUEST_STATUS.LOADING
    })
    .addCase(logout.failure, (state) => {
      state.logoutStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(logout.success, (state) => {
      state.logoutStatus = REQUEST_STATUS.SUCCESS
      state.firebase_user = null
      state.episode_history_shelf = {}
      state.bookmarks = {}
      state.history = {}
      state.data = {}
    })
    .addCase(deleteUser.request, (state) => {
      reset(state)
      state.deleteUserStatus = REQUEST_STATUS.LOADING
    })
    .addCase(deleteUser.failure, (state) => {
      state.deleteUserStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(deleteUser.success, (state) => {
      state.deleteUserStatus = REQUEST_STATUS.SUCCESS
    })
    .addCase(updateUserData, (state, { payload: { data, type } }) => {
      state[type] = data || {}
    })
    .addCase(setLang, (state, { payload }) => {
      state.lang = payload
    })
    .addCase(hideModal, (state) => {
      reset(state)
    })
    .addCase(
      fetchMaterial.success,
      (
        state,
        {
          payload: {
            response: {
              data: { root }
            }
          }
        }
      ) => {
        state.last_opened_material = root
        state.last_opened_special = null
      }
    )
    .addCase(
      fetchSpecials.success,
      (
        state,
        {
          payload: {
            response: { data }
          }
        }
      ) => {
        state.last_opened_material = null
        state.last_opened_special = data
      }
    )
    .addCase(getFeedbackToken.request, (state) => {
      state.feedbackTokenStatus = REQUEST_STATUS.LOADING
    })
    .addCase(getFeedbackToken.failure, (state) => {
      state.feedbackTokenStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(
      getFeedbackToken.success,
      (
        state,
        {
          payload: {
            response: {
              data: { csrf }
            }
          }
        }
      ) => {
        state.feedbackTokenStatus = REQUEST_STATUS.SUCCESS
        state.feedback_token = csrf
      }
    )
    .addCase(subscribeToDaily.request, (state) => {
      state.subscribeToDailyStatus = REQUEST_STATUS.LOADING
      state.daily.error = false
    })
    .addCase(subscribeToDaily.failure, (state) => {
      state.subscribeToDailyStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(
      subscribeToDaily.success,
      (
        state,
        {
          payload: {
            response: { data }
          }
        }
      ) => {
        if (data.status === 'success') {
          state.daily.subscribed = true
          state.subscribeToDailyStatus = REQUEST_STATUS.SUCCESS
        }

        if (data.status === 'error') {
          state.daily.error = true
          state.subscribeToDailyStatus = REQUEST_STATUS.FAILURE
        }
      }
    )
    .addCase(subscribeToSOS.request, (state) => {
      state.subscribeToSOSStatus = REQUEST_STATUS.LOADING
      state.sos.error = false
    })
    .addCase(subscribeToSOS.failure, (state) => {
      state.subscribeToSOSStatus = REQUEST_STATUS.FAILURE
      state.sos.error = true
    })
    .addCase(
      subscribeToSOS.success,
      (
        state,
        {
          payload: {
            response: { data }
          }
        }
      ) => {
        if (data.status === 'success') {
          state.sos.subscribed = true
          state.subscribeToSOSStatus = REQUEST_STATUS.SUCCESS
        }

        if (data.status === 'error') {
          state.sos.error = true
          state.subscribeToSOSStatus = REQUEST_STATUS.FAILURE
        }
      }
    )
    .addCase(resetDaily, (state) => {
      state.daily.error = false
      state.daily.subscribed = false
    })
    .addCase(sendMessage.request, (state) => {
      state.sendMessageStatus = REQUEST_STATUS.LOADING
    })
    .addCase(sendMessage.failure, (state) => {
      state.sendMessageStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(sendMessage.success, (state) => {
      state.sendMessageStatus = REQUEST_STATUS.SUCCESS
    })
    .addCase(callToPushNotifications.request, (state) => {
      state.callToPushNotificationsStatus = REQUEST_STATUS.LOADING
    })
    .addCase(callToPushNotifications.failure, (state) => {
      state.callToPushNotificationsStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(callToPushNotifications.success, (state) => {
      state.callToPushNotificationsStatus = REQUEST_STATUS.SUCCESS
    })
    .addCase(
      setFirebaseTopicStatus,
      (state, { payload: { topic, status } }) => {
        state.firebase_topics[topic] = status
      }
    )
    .addCase(setFirebasePushToken, (state, { payload: token }) => {
      state.firebase_token = token
    })
    .addCase(sendTextError.request, (state) => {
      state.sendTextErrorStatus = REQUEST_STATUS.LOADING
    })
    .addCase(sendTextError.failure, (state) => {
      state.sendTextErrorStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(sendTextError.success, (state) => {
      state.sendTextErrorStatus = REQUEST_STATUS.SUCCESS
    })
    .addCase(sendReaction.request, (state) => {
      state.sendReactionStatus = REQUEST_STATUS.LOADING
    })
    .addCase(sendReaction.failure, (state) => {
      state.sendReactionStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(sendReaction.success, (state) => {
      state.sendReactionStatus = REQUEST_STATUS.SUCCESS
    })

    .addCase(resetStatuses, (state) => {
      reset(state)
    })
})
