import { ChapterBlock } from '@client/types'

export const generateFeatureChapters = (materialContent) => {
  const chapterData = []

  const updatedMaterial = structuredClone(materialContent)
  const allowedTypes = updatedMaterial?.table_of_contents?.data?.tags
  const hasH2 = allowedTypes.includes('h2')

  for (let i = 0; i < updatedMaterial?.content?.blocks.length; i++) {
    const currentBlock = updatedMaterial?.content?.blocks[i]
    const nextBlock = updatedMaterial?.content?.blocks[i + 1]

    // Если в структуре оглавления есть 'h2', то мы формируем объект с названием главы ('chapter-subtitle'),
    // описанием главы ('h2') и также добавляем индекс элемента.
    // Блок 'h2' всегда идет после 'chapter-subtitle', но может отсутствовать.
    if (hasH2 && currentBlock?.type === 'chapter-subtitle') {
      const chapter = nextBlock?.type === 'h2' ? nextBlock.data : null
      chapterData.push({ ...currentBlock, chapter, index: i })
      continue
    }

    // Во всех остальных случаях добавляем те блоки, чьи типы имеются в структуре оглавления (например 'h3', 'h4').
    // Если нам попадается блок 'h2' мы его никогда не добавляем, потому что уже добавили вместе с 'chapter-subtitle'
    if (
      currentBlock?.type !== 'h2' &&
      allowedTypes.includes(currentBlock?.type)
    ) {
      chapterData.push({ ...currentBlock, index: i })
    }
  }

  const indexOfChapter = updatedMaterial?.content?.blocks.findIndex(
    (item) => item.type === 'chapter'
  )

  // Если блок Оглавления отсутствует, то создаем его и кладем после блока lead_hr
  if (indexOfChapter === -1) {
    // Найти индекс элемента с типом 'lead_hr'
    let indexOfCover = updatedMaterial?.content?.blocks.findIndex(
      (item) => item.type === 'lead_hr'
    )

    // Если блока 'lead_hr' нет, то находим индекс ближайшего элемента структуры Оглавления и указываем индекс
    // на 1 меньше, чтобы в будущем положить туда блок Оглавление
    if (indexOfCover === -1) {
      indexOfCover = chapterData[0].index - 1
    }

    // Создать новый элемент для вставки
    const ChapterElement: ChapterBlock = {
      type: 'chapter',
      data: chapterData,
      tags: allowedTypes,
      id: 'chapterBlock_id',
      display: updatedMaterial?.container?.display
    }

    // Вставить блок Оглавление после элемента с типом 'lead_hr' или перед ближайшим элементом структуры Оглавления
    updatedMaterial?.content?.blocks.splice(indexOfCover + 1, 0, ChapterElement)
  }
  return updatedMaterial
}
