import React from 'react'
import { makeClassName } from '@client/utils/makeClassName'
import { validateEmail } from '@utils/validateEmail'

import styles from './SubscriptionForm.module.css'

export const SubscriptionForm = ({
  data,
  lang,
  handleSubscribeToDaily,
  handleSubscribeToSOS,
  handleSubscribe,
  isDailySubscription,
  email,
  error,
  daily,
  sos,
  handleInput,
  renderSuccessMessage
}) => {
  let handleSubmit

  switch (true) {
    case lang === 'ru':
      handleSubmit = handleSubscribeToSOS
      break
    case isDailySubscription:
      handleSubmit = handleSubscribeToDaily
      break
    default:
      handleSubmit = handleSubscribe
  }

  return (
    <form
      className={makeClassName([
        [styles.root, lang === 'ru'],
        [styles.rootEn, lang === 'en']
      ])}
      onSubmit={handleSubmit}
    >
      <div
        className={makeClassName([
          [styles.form, true],
          [styles.successedForm, email && (daily.subscribed || sos.subscribed)]
        ])}
      >
        <div
          className={makeClassName([
            [styles.field, true],
            [
              styles.invalid,
              (email && error) || (email && (daily.error || sos.error))
            ]
          ])}
        >
          <label htmlFor="footer-email">
            <span>Email</span>
          </label>
          <input
            className={styles.input}
            type="email"
            defaultValue={email}
            onInput={handleInput}
            id="footer-email"
            placeholder="Email"
          />
          {email && error && (
            <span className={styles.message}>Ошибка! Попробуйте еще раз</span>
          )}

          {email && !error && (daily.error || sos.error) && (
            <span
              className={styles.message}
              dangerouslySetInnerHTML={{ __html: data.something_wrong }}
            />
          )}
        </div>

        <button
          className={styles.button}
          disabled={!validateEmail(email)}
          type="submit"
          aria-label={data.cta}
        >
          <span className={styles.buttonLabel}>{data.cta}</span>
          <span className={styles.buttonIcon}>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 16"
            >
              <path
                d="M24 8.005a1.21 1.21 0 0 0-.333-.847L17.39.372a1.232 1.232 0 0 0-.361-.279.9.9 0 0 0-.4-.093.972.972 0 0 0-.734.31 1.1 1.1 0 0 0-.285.775c0 .165.025.316.076.454.057.13.133.248.228.351l2.134 2.345L21.6 7.757l.333-.671-3.304-.217H1.057c-.317 0-.574.107-.771.32-.19.207-.286.479-.286.816 0 .33.095.603.286.816.197.207.454.31.771.31H18.63l3.304-.207-.333-.66-3.552 3.511-2.134 2.335a1.214 1.214 0 0 0-.228.361c-.051.138-.076.286-.076.444 0 .31.095.569.285.775.197.207.442.31.734.31.279 0 .53-.117.752-.351l6.286-6.797A1.21 1.21 0 0 0 24 8.005Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </button>
      </div>

      {email &&
        (daily.subscribed || sos.subscribed) &&
        renderSuccessMessage(data.success_message, email)}
    </form>
  )
}
