import React from 'react'
import { EpisodeCoverBlock } from '@client/types'
import {
  AudioBlock,
  MetaBlock,
  RichTitleBlock,
  TagBlock,
  ThemeData
} from '@client/types'
import { generateGradientEpisode } from '@client/utils/generateGradientEpisode'
import { Link } from '@common/Link'
import { makeClassName } from '@utils/makeClassName'

import { Image, MetaItem, RichTitle, Tag, Timestamp } from '@meduza/ui-kit-2'
import { TagThemes } from '@meduza/ui-kit-2/dist/Tag/Tag.types'

import { PlayButton } from '../../AudioPlayer'
import { Progress } from '../../AudioPlayer'
import { ProgressTypeEnums } from '../../AudioPlayer/constants'
import { BookmarkIcon } from '../../BookmarkIcon'
import { DownloadIcon } from '../../DownloadIcon'

import styles from './EpisodeCover.module.css'

/*
 * Обложка эпизода для материалов
 */

export interface EpisodeCoverProps {
  block: EpisodeCoverBlock
  styleContext: string | string[]
  episodeTheme?: ThemeData | ''
  type?: string
}

export const EpisodeCover: React.FC<EpisodeCoverProps> = ({
  block,
  styleContext,
  episodeTheme,
  type
}) => {
  const {
    url,
    blocks,
    cover: {
      urls,
      ambiance,
      gradients,
      urls: { blured_background_jpg: bluredBackground }
    }
  } = block.data

  const backgroundStyle: { backgroundColor?: string; color?: string } = {}
  const gradientStyle: { backgroundImage?: string } = {}
  let theme = 'dark'
  let bookmarkTheme = 'light'

  const isEmbededType =
    styleContext.includes('episode') ||
    styleContext.includes('no_mods') ||
    styleContext.includes('slide') ||
    styleContext.includes('rich') ||
    styleContext.includes('center') ||
    styleContext.includes('isInCard')

  if (gradients) {
    theme = gradients.text_rgb === '0,0,0' ? 'dark' : 'light'
    bookmarkTheme = gradients.text_rgb === '0,0,0' ? 'light' : 'dark'
    backgroundStyle.backgroundColor = `rgb(${gradients.bg_rgb})`
    backgroundStyle.color = `rgb(${gradients.text_rgb})`
    gradientStyle.backgroundImage = generateGradientEpisode(
      gradients.bg_rgb,
      5,
      -180
    )
  }

  if (episodeTheme) {
    theme = episodeTheme.text_color === '0,0,0' ? 'dark' : 'light'
    bookmarkTheme = episodeTheme.text_color === '0,0,0' ? 'light' : 'dark'
    backgroundStyle.backgroundColor = `rgb(${episodeTheme.background_color})`
    backgroundStyle.color = `rgb(${episodeTheme.text_color})`
  }

  const Background = () =>
    bluredBackground ? (
      <div
        className={styles.background}
        style={{ backgroundImage: `url(${bluredBackground})` }}
      />
    ) : null

  if (isEmbededType) {
    type = 'isEmbeded'
  }

  const tagData = blocks.find(
    (block): block is TagBlock => block.type === 'tag'
  )

  const richTitleData = blocks.find(
    (block): block is RichTitleBlock => block.type === 'rich_title'
  )

  // Преобразовываем чтобы избежать ошибки в TS
  const processedRichTitleData = richTitleData
    ? {
        ...richTitleData,
        data: {
          ...richTitleData.data,
          as: richTitleData.data.as as keyof JSX.IntrinsicElements | undefined
        }
      }
    : null

  const metaData = blocks.find(
    (block): block is MetaBlock => block.type === 'meta'
  )

  const durationData = metaData.data.components.find(
    (component) => component.type === 'duration'
  )

  const datetimeData = metaData.data.components.find(
    (component) => component.type === 'datetime'
  )

  const audioData = blocks.find(
    (block): block is AudioBlock => block.type === 'audio'
  )

  return (
    <div
      className={makeClassName(
        [
          [styles.root, true],
          [styles.withAmbiance, !!ambiance],
          [styles[theme], true],
          [styles.rootInMaterial, type === 'isInMaterial'],
          [styles.rootInEmbed, type === 'isEmbeded']
        ].concat(
          Array.isArray(styleContext)
            ? styleContext.map((ctx) => [styles[ctx], !!ctx && styles[ctx]])
            : [[styles[styleContext], !!styleContext && !!styles[styleContext]]]
        )
      )}
      style={backgroundStyle}
    >
      {type === 'isInMaterial' ? (
        <Background />
      ) : (
        <Link className={styles.hiddenLink} to={`/${url}`}>
          <Background />
        </Link>
      )}

      {ambiance && (
        <div className={styles.ambiance}>
          <Image
            optimized={ambiance}
            ratio={null}
            display="narrow"
            styleContext={styleContext}
          />
          <div className={styles.gradientImage} />
          <div className={styles.gradientBackground} style={gradientStyle} />
        </div>
      )}

      <div
        className={makeClassName([
          [styles.image, true],
          [styles.imageInMaterial, type === 'isInMaterial'],
          [styles.imageInEmbed, type === 'isEmbeded']
        ])}
      >
        <Image optimized={urls} ratio={1} display="narrow" />
      </div>
      <div
        className={makeClassName([
          [[styles.body], true],
          [[styles.bodyWithAmbiance], !!ambiance],
          [[styles.bodyInMaterial], type === 'isInMaterial'],
          [[styles.bodyInEmbed], type === 'isEmbeded']
        ])}
      >
        {tagData && (
          <div
            className={makeClassName([
              [styles.tagContainer, true],
              [styles.tagContainerInMaterial, type === 'isInMaterial'],
              [styles.tagContainerInEmbed, type === 'isEmbeded']
            ])}
          >
            <Tag size="small" theme={theme as TagThemes}>
              {tagData.data.text}
            </Tag>
          </div>
        )}

        {richTitleData && (
          <RichTitle
            block={processedRichTitleData}
            styleContext={isEmbededType ? '' : styleContext}
            size={type === 'isInMaterial' ? 'large' : 'medium'}
          />
        )}

        <div
          className={makeClassName([
            [styles.audioPanel, true],
            [styles.audioPanelInMaterial, type === 'isInMaterial'],
            [styles.audioPanelInEmbed, type === 'isEmbeded']
          ])}
        >
          <div className={styles.mainPanel}>
            {audioData && (
              <div
                className={makeClassName([
                  [[styles.button], true],
                  [[styles.buttonPlay], true],
                  [[styles.buttonPlayLight], theme === 'light'],
                  [[styles.buttonPlayDark], theme === 'dark']
                ])}
              >
                <div className={styles.play}>
                  <Progress
                    episodeUrl={audioData.data.url || audioData.data.audio.url}
                    duration={audioData?.data?.audio?.mp3_duration}
                    type={ProgressTypeEnums.CIRCLE}
                    styleContext="isInDynamicBlock"
                    theme={theme}
                  />
                  <PlayButton
                    block={audioData}
                    episodeUrl={audioData.data.url || audioData.data.audio.url}
                    styleContext="isInDynamicBlock"
                    theme={theme}
                  />
                </div>
              </div>
            )}
            <div className={styles.metaContainer}>
              {durationData && (
                <MetaItem hasSource={false} bullets key={durationData.id}>
                  {durationData.text}
                </MetaItem>
              )}

              {datetimeData && (
                <MetaItem
                  hasSource={false}
                  bullets
                  key={datetimeData.id}
                  type="datetime"
                >
                  <Timestamp
                    publishedAt={datetimeData.datetime}
                    type="fromNow"
                    locale={metaData.data.lang}
                  />
                </MetaItem>
              )}
            </div>
          </div>
          <div
            className={makeClassName([
              [styles.additionalPanel, true],
              [styles.additionalPanelInMaterial, type === 'isInMaterial']
            ])}
          >
            <DownloadIcon
              theme={bookmarkTheme}
              url={audioData?.data?.audio?.mp3_url}
            />
            <div className={styles.bookmark}>
              <BookmarkIcon
                theme={bookmarkTheme || audioData.data.theme}
                url={audioData.data.url || audioData.data.audio.url}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
