import React, { useEffect, useState } from 'react'
import { AllSeasonsData } from '@client/types'
import { ALERT_TEXTS, TYPES, useAlert } from '@common/Alert'

import { Button, Dropdown, Menu } from '@meduza/ui-kit-2'

import { Episodes } from '../Episodes'

import styles from './PodcastSeason.module.css'

export const PodcastSeason = ({ data, lang }) => {
  const [title, setTitle] = useState(data.current_season.title)
  const [episodes, setEpisodes] = useState(data.current_season.episodes)
  const [isMenuClicked, setIsMenuClicked] = useState(false)
  const [isError, setIsError] = useState(false)

  const { show } = useAlert()

  const handleClick = async (item: AllSeasonsData) => {
    try {
      setTitle(item.title)
      setIsMenuClicked(true)

      const response = await fetch(`/api/w5/${item.slug}`)

      const result = await response.json()

      setEpisodes(result.episodes)
      setIsError(false)

      setTimeout(() => {
        setIsMenuClicked(false)
      }, 200)
    } catch (error) {
      console.error(error)
      setIsError(true)
    }
  }

  useEffect(() => {
    if (isError) {
      show(ALERT_TEXTS.ON_ERROR, {
        type: TYPES.ERROR
      })
    }
    setIsError(false)
  }, [show, isError])

  return (
    <div>
      <div className={styles.panel}>
        {data?.all_seasons && (
          <div className={styles.subscribe}>
            <Dropdown
              active={isMenuClicked ? false : true}
              styleContext="isPodcastSeason"
            >
              <Button size="auto" theme="ghost" styleContext="isInPodcast">
                {title}
                <div className={styles.arrow} />
              </Button>
              <Menu>
                <ul>
                  {data.all_seasons.map((item: AllSeasonsData) => (
                    <li key={item.id}>
                      <button
                        className={styles.menuButton}
                        onClick={() => handleClick(item)}
                        disabled={title === item.title}
                      >
                        {item.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </Menu>
            </Dropdown>
          </div>
        )}
      </div>
      <Episodes
        key={data?.current_season?.id}
        data={episodes}
        shouldUsePagination={false}
        lang={lang}
        isPodcastSeason={true}
      />
    </div>
  )
}
