import { COLLECTIONS } from '@client/enums'
import { Lang } from '@client/types'
import { createAction } from '@reduxjs/toolkit'
import { createAsyncAction } from '@utils/createAsyncAction'
import firebase from 'firebase/compat/app'
import {
  FeedbackTokenResponse,
  SubscribeToDailyResponse,
  SubscribeToSOSResponse
} from 'types/apiTypes'
import {
  CallToPushNotificationsPayload,
  FirebaseSendMessagePayload,
  SendReactionPayload,
  SendTextErrorPayload,
  SubscribeToDailyPayload,
  SubscribeToSOSPayload
} from 'types/storeTypes'

/*
 Логин через google для firebase
 */

export const loginGoogle = createAsyncAction<
  undefined,
  firebase.User,
  undefined
>('@USER/LOGIN_GOOGLE')

/*
 Отправка magic-link на email через firebase
 */

export const sendEmail = createAsyncAction<
  {
    backRoute: string
    email: string
  },
  undefined,
  undefined
>('@USER/SEND_EMAIL')

/*
 Вызывается когда юзер залогинился через email (открыл сайт по magic-link)
 */

export const emailLogin = createAction<firebase.User>('@USER/EMAIL_LOGIN')

/*
 Вызывается при загрузке сайта если в localStorage есть данные о firebase_user
 нужен для того, чтобы включить listeners для закладок, прочитанных материалов
 и данных подкастов
 */

export const userIsLoggedIn = createAction<firebase.User>(
  '@USER/USER_IS_LOGGED_IN'
)

export const logout = createAsyncAction<undefined, undefined, undefined>(
  '@USER/LOGOUT'
)

export const deleteUser = createAsyncAction<undefined, undefined, undefined>(
  '@USER/DELETE_USER'
)

/*
 Отправка сообщения из меню юзера
 */

export const sendMessage = createAsyncAction<
  FirebaseSendMessagePayload,
  undefined,
  undefined
>('@USER/SEND_MESSAGE')

/*
 Токен для того, что бы послать текст в редакцию
 */

export const getFeedbackToken = createAsyncAction<
  undefined,
  { response: FeedbackTokenResponse },
  undefined
>('@USER/GET_FEEDBACK_TOKEN')

/*
 Подписка на рассылку
 */

export const subscribeToDaily = createAsyncAction<
  SubscribeToDailyPayload,
  { response: SubscribeToDailyResponse },
  undefined
>('@USER/SUBSCRIBE_TO_DAILY')

/*
 Подписка на рассылку SOS
 */

export const subscribeToSOS = createAsyncAction<
  SubscribeToSOSPayload,
  { response: SubscribeToSOSResponse },
  undefined
>('@USER/SUBSCRIBE_TO_SOS')

/*
 Отправка опечатки
 */

export const sendTextError = createAsyncAction<
  SendTextErrorPayload,
  undefined,
  undefined
>('@USER/SEND_TEXT_ERROR')

/*
 Отправка текста ("напишите нам")
 */

export const sendReaction = createAsyncAction<
  SendReactionPayload,
  undefined,
  undefined
>('@USER/SEND_REACTION')

export const setFirebasePushToken = createAction<string>(
  '@USER/SET_FIREBASE_PUSH_TOKEN'
)

export const setFirebaseTopicStatus = createAction<{
  topic: string
  status: string
}>('@USER/SET_FIREBASE_TOPIC_STATUS')

export const callToPushNotifications = createAsyncAction<
  CallToPushNotificationsPayload,
  undefined,
  undefined
>('@USER/CALL_TO_PUSH_NOTIFICATIONS')

/*
 Firebase: пометка прочитанного материала (75%)
 */

export const markMaterialRead = createAction<string>('@USER/MARK_MATERIAL_READ')

/*
 Firebase: добавление/удаление закладок
 */

export const toggleBookmark = createAction<string>('@USER/TOGGLE_BOOKMARK')

/*
 Экшен для того чтобы сбрасывать статусы запросов
 */

export const resetStatuses = createAction('@USER/RESET_STATUSES')

/*
 Экшен для того чтобы сбрасывать статус подписки на рассылку
 */

export const resetDaily = createAction('@USER/RESET_DAILY')

/*
 Firebase: универсальный экшен для обновления данных из файрбейс
 используется листенерами
 */

export const updateUserData = createAction<{
  data: firebase.firestore.DocumentData
  type: COLLECTIONS
}>('@USER/UPDATE_USER_DATA')

export const setLang = createAction<Lang>('@USER/SET_LANG')
