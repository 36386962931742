import React from 'react'

import styles from './ToggleSubscription.module.css'

export const ToggleSubscription = ({
  data,
  isDailySubscription,
  handleToggleSubscription
}) => {
  return (
    <div className={styles.root}>
      <h2 className={styles.tag}>{data.tag}</h2>
      <div className={styles.subscriptionToggle}>
        <input
          type="radio"
          id="subscription-toggle-monthly"
          name="subscription"
          value="monthly"
          checked={!isDailySubscription}
          onChange={handleToggleSubscription}
        />
        <label htmlFor="subscription-toggle-monthly">Monthly</label>

        <input
          type="radio"
          id="subscription-toggle-daily"
          name="subscription"
          value="daily"
          onChange={handleToggleSubscription}
        />
        <label htmlFor="subscription-toggle-daily">Daily</label>
      </div>
    </div>
  )
}
