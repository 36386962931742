import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEpisodeDataById } from '@selectors/audioPlayerSelectors'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { requestRewind } from '@store/AudioPlayer/audioPlayerActions'
import { makeClassName } from '@utils/makeClassName'

import { ProgressTypeEnums } from '../constants'

import styles from './Progress.module.css'

/*
 * Универсальный прогресс для всех видов плееров
 */

interface ProgressProps {
  episodeUrl: string
  duration: number
  type: string
  styleContext: string
  theme?: string
}

export const Progress: React.FC<ProgressProps> = ({
  episodeUrl,
  duration,
  type,
  styleContext,
  theme
}) => {
  const dispatch = useDispatch()

  const byId = useSelector(getEpisodeDataById)
  const currentUser = useSelector(getCurrentUser)
  const { episode_history_shelf } = currentUser

  const time = (byId[episodeUrl] && byId[episodeUrl].time) || null

  const safariShit = duration === Infinity || time === Infinity
  const ownProgress =
    !duration || !time || safariShit ? 0 : Math.floor((100 / duration) * time)

  const savedProgress = ownProgress || episode_history_shelf[episodeUrl] * 100

  const possibleProgress = byId[episodeUrl] && byId[episodeUrl].possibleProgress

  const progress = savedProgress > 100 || !savedProgress ? 0 : savedProgress

  const userInteracted =
    !!byId[episodeUrl] || !!episode_history_shelf[episodeUrl]

  const [rewinding, setRewinding] = useState(false)

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    const { currentTarget, clientX } = event
    setRewinding(true)
    const { left, width } = currentTarget.getBoundingClientRect()
    const percent = ((clientX - left) * 100) / width
    if (percent >= 0 && percent <= 100) {
      dispatch(requestRewind({ episodeUrl, progress: percent }))
    }
  }

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (rewinding) {
      const { currentTarget, clientX } = event
      const { left, width } = currentTarget.getBoundingClientRect()
      const percent = ((clientX - left) * 100) / width
      if (percent >= 0 && percent <= 100) {
        dispatch(requestRewind({ episodeUrl, progress: percent }))
      }
    }
  }

  const handleMouseUp = () => {
    setRewinding(false)
  }

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!event.changedTouches || !event.changedTouches[0]) {
      return
    }

    const { currentTarget, changedTouches } = event
    const { clientX } = changedTouches[0]

    setRewinding(true)

    const { left, width } = currentTarget.getBoundingClientRect()
    const percent = ((clientX - left) * 100) / width

    if (percent >= 0 && percent <= 100) {
      dispatch(requestRewind({ episodeUrl, progress: percent }))
    }
  }

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    const { currentTarget, changedTouches } = event

    if (!rewinding || !changedTouches || !changedTouches[0]) {
      return
    }

    const { left, width } = currentTarget.getBoundingClientRect()
    const percent = ((changedTouches[0].clientX - left) * 100) / width

    if (percent >= 0 && percent <= 100) {
      dispatch(requestRewind({ episodeUrl, progress: percent }))
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp)
    return () => document.removeEventListener('mouseup', handleMouseUp)
  }, [])

  const renderProgress = () => {
    switch (type) {
      case ProgressTypeEnums.CIRCLE: {
        const strokeOffset = !userInteracted ? 365 : (progress * 365) / 100
        return (
          <svg viewBox="0 0 120 120" className={styles.circleWrap}>
            <circle className={styles.track} cx="60" cy="60" r="58" />
            <circle
              className={styles.played}
              cx="60"
              cy="60"
              r="58"
              style={{
                strokeDasharray: 365,
                strokeDashoffset: 365 - strokeOffset
              }}
            />
          </svg>
        )
      }

      default: {
        return (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className={styles.track}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <div
              className={styles.played}
              style={{
                width: possibleProgress
                  ? `${possibleProgress}%`
                  : `${progress}%`
              }}
            />
          </div>
        )
      }
    }
  }

  return (
    <div
      className={makeClassName([
        [styles[type], !!type && !!styles[type]],
        [!!theme && styles[theme], !!theme && !!styles[theme]],
        [styles[styleContext], !!styleContext && !!styles[styleContext]]
      ])}
      data-unplayed={time === null || 0}
    >
      {renderProgress()}
    </div>
  )
}
