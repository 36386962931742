import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  MobileItem,
  ScreenDocumentWithMobile,
  ScreenRotationDocument
} from '@client/types'
import { BriefBlock } from '@routes/Screen/BriefBlock'
import { BriefSubscriptionBlock } from '@routes/Screen/BriefSubscriptionBlock'
import { DynamicBlock } from '@routes/Screen/DynamicBlock'
import { EpisodeBlock } from '@routes/Screen/EpisodeBlock'
import { ExchangeBlock } from '@routes/Screen/ExchangeBlock'
import { InformerBlock } from '@routes/Screen/InformerBlock'
import { MediaBlock } from '@routes/Screen/MediaBlock'
import { PodcastListBlock } from '@routes/Screen/PodcastListBlock'
import { RichBlock } from '@routes/Screen/RichBlock'
import { SimpleBlock } from '@routes/Screen/SimpleBlock'
import { SupportBlock } from '@routes/Screen/SupportBlock'
import {
  getScreenDocuments,
  getScreenRotationDocuments
} from '@selectors/screenDocumentsSelectors'
import { makeClassName } from '@utils/makeClassName'
import { randomIntFromInterval } from '@utils/randomIntFromInterval'

import { BookLandingBlock } from '../BookLandingBlock'

/*
 * Лента блоков для экранов мобилы
 */
import styles from './Feed.module.css'

interface RenderBlockProps {
  block: ScreenDocumentWithMobile
  starred?: boolean
  origin: string[]
}

const RenderBlock: React.FC<RenderBlockProps> = ({
  block,
  starred,
  origin
}): JSX.Element | null => {
  const screenDocuments = useSelector(getScreenDocuments)
  const screenRotationDocuments: Record<
    string,
    ScreenRotationDocument
  > = useSelector(getScreenRotationDocuments)

  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  switch (block.mobile_layout) {
    case 'block_title': {
      return (
        <header className={styles.header} key={block.key}>
          <h2>{block.title}</h2>
        </header>
      )
    }

    case 'media': {
      return (
        <div className={styles.block} key={block.url}>
          <MediaBlock block={block} correlation="is1to2" origin={origin[0]} />
        </div>
      )
    }

    case 'episode': {
      return (
        <div className={styles.block} key={block.url}>
          <DynamicBlock block={block} origin={origin && origin[0]} />
        </div>
      )
    }

    case 'rotation': {
      const rotationDocument = screenRotationDocuments[block.url]
      const rotationUrls = rotationDocument.collection.map((d) => d.key)
      const randomIndex = isRendered
        ? randomIntFromInterval(0, rotationUrls.length - 1)
        : 0
      const currentRotationUrl = rotationUrls[randomIndex]
      const pickedDocument = screenDocuments.byId[currentRotationUrl]
      return <RenderBlock block={pickedDocument} origin={origin} />
    }

    case 'informer': {
      return (
        <div key="informer" className={styles.block}>
          <InformerBlock block={block} />
        </div>
      )
    }

    case 'rates': {
      return (
        <div key="rates" className={styles.block}>
          <ExchangeBlock />
        </div>
      )
    }

    case 'simple': {
      return (
        <div
          key={block.url}
          className={makeClassName([
            [styles.block, true],
            [styles.starred, !!starred]
          ])}
        >
          <SimpleBlock block={block} correlation="is1to2" />
        </div>
      )
    }

    case 'rich':
    case 'card':
    case 'podcast': {
      return (
        <div className={styles.block} key={block.url}>
          <RichBlock
            block={block}
            correlation="isMobile"
            origin={origin && origin[0]}
          />
        </div>
      )
    }

    case 'podcasts_list': {
      return (
        <div className={styles.block} key={block.url}>
          <PodcastListBlock block={block} origin={origin && origin[0]} />
        </div>
      )
    }

    case 'signal_subscription':
    case 'brief_subscription': {
      return (
        <div className={styles.block} key={block.url}>
          <BriefSubscriptionBlock correlation="is1to3" />
        </div>
      )
    }

    case 'brief': {
      return (
        <div className={styles.block} key={block.url}>
          <BriefBlock block={block} correlation="is1to3" />
        </div>
      )
    }

    case 'dynamic': {
      return (
        <div className={styles.block} key={block.url}>
          <DynamicBlock block={block} origin={origin && origin[0]} />
        </div>
      )
    }

    case 'book_landing': {
      return (
        <div className={styles.block} key={block.url}>
          <BookLandingBlock
            block={block}
            correlation="is1to2"
            origin={origin && origin[0]}
          />
        </div>
      )
    }

    case 'rich_promo': {
      return (
        <div key={block.url} className={styles.block}>
          <SupportBlock block={block} correlation="isMobile" />
        </div>
      )
    }

    default: {
      return null
    }
  }
}

interface RenderBlocksProps {
  block: MobileItem
  screenDocuments: Record<string, ScreenDocumentWithMobile>
}

const RenderBlocks: React.FC<RenderBlocksProps> = ({
  block,
  screenDocuments
}) => {
  if (block.groupped) {
    return (
      <>
        <div key={`grouped-${block.keys.join('-')}`} className={styles.group}>
          {block.keys.map((item) => (
            <div className={styles.groupItem} key={item}>
              <RenderBlock
                block={screenDocuments[item]}
                origin={block.origin}
              />
            </div>
          ))}
        </div>
        {block.meta?.link && (
          <footer className={styles.footer}>
            <Link to={block.meta.link.path}>Full News Feed</Link>
          </footer>
        )}
      </>
    )
  }
  return (
    <RenderBlock
      block={screenDocuments[block.key]}
      starred={block.starred}
      origin={block.origin}
    />
  )
}

interface RenderSectionBlocksProps {
  section: MobileItem[]
  screenDocuments: Record<string, ScreenDocumentWithMobile>
}
const RenderSectionBlocks: React.FC<RenderSectionBlocksProps> = ({
  section,
  screenDocuments
}) => {
  return (
    <div className={styles.container}>
      {section.map((block, i) => (
        <RenderBlocks
          key={`${block.key}-${i}`}
          block={block}
          screenDocuments={screenDocuments}
        />
      ))}
    </div>
  )
}

interface FeedProps {
  blocks: MobileItem[][]
}

const FeedComponent: React.FC<FeedProps> = ({ blocks }) => {
  const screenDocuments = useSelector(getScreenDocuments).byId

  return (
    <section className={styles.root}>
      {blocks.map((section) => {
        return (
          <RenderSectionBlocks
            section={section}
            key={`section-${section.map((block) => block.key).join('-')}`}
            screenDocuments={screenDocuments}
          />
        )
      })}
    </section>
  )
}

export const Feed = memo(FeedComponent)

Feed.whyDidYouRender = true
